<template>
  <CContainer>
    <CRow class="justify-content-center align-items-center min-vh-100">
      <CCol md="6">
        <CCard class="p-4">
          <CCardBody>
            <CForm>
              <h1>Login</h1>
              <p class="text-muted">Sign In to your account</p>
              <CInput placeholder="Username" autocomplete="username email" v-model="form.email">
                <template #prepend-content>
                  <CIcon name="cil-user"/>
                </template>
              </CInput>
              <CInput
                placeholder="Password"
                type="password"
                autocomplete="curent-password"
                v-model="form.password"
              >
                <template #prepend-content>
                  <CIcon name="cil-lock-locked"/>
                </template>
              </CInput>

              <!-- <vue-recaptcha
                class="mb-4"
                ref="recaptcha"
                :sitekey="sitekey"
                @verify="onVerify"
                @expired="onExpired"
              />-->

              <CRow>
                <CCol col="6">
                  <CButton color="primary" class="px-4" @click="handlerLogin()">
                    <CSpinner v-if="loading" color="info" size="sm"/>

                    <span v-else>Login</span>
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>

        <CToaster v-if="error" position="bottom-center">
          <CToast :show="true" style="background: #e1a82d">{{error}}</CToast>
        </CToaster>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import VueRecaptcha from "vue-recaptcha";

export default {
  name: "Login",

  components: { VueRecaptcha },

  data: () => ({
    form: {
      email: null,
      password: null,
      recaptcha: null
    },

    error: null,

    recaptcha: "",
    sitekey: "6LdZP98UAAAAAEE17Qgbr-Ge12Zmldte03l5cevk"
  }),

  computed: {
    ...mapGetters({
      domen: "main/domen",
      loading: "auth/loading"
    })
  },

  methods: {
    ...mapActions({
      login: "auth/login"
    }),

    handlerLogin() {
      console.log("handlerLogin");
      this.error = null;

      this.login({
        data: this.form,
        cb: () => {
          this.error = null;

          this.$router.push("/");
        },
        cbError: error => {
          this.error = error;
        }
      });
    },

    onSubmit() {
      this.$refs.invisibleRecaptcha.execute();
    },

    onVerify(response) {
      this.setTokenCaptcha(response);
    },

    onExpired() {
      this.setTokenCaptcha("");
      this.resetRecaptcha();
    },

    resetRecaptcha() {
      this.setTokenCaptcha("");
      this.$refs.recaptcha.reset();
    },

    setTokenCaptcha(recaptchaToken) {
      this.form.recaptcha = recaptchaToken;
    }
  }
};
</script>
